// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apikeywebcaptcha: '6LdPzdUUAAAAAFxz8lcsJp7ER_etT29W6fBTXywm',
  url_api: 'http://localhost:3000',
  //url_api_v2: 'http://localhost:5100',
  url_api_v2: 'https://api-optica.fly.dev',
  url_cloudfront: 'https://d13sr9fiyrld8b.cloudfront.net',
  apiKey: '7BFF427D95A57A51AFF32CCA74C66',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
