<app-header></app-header>

<!-- <div class="container">
    <router-outlet></router-outlet>
</div>
<app-footer></app-footer> -->

<!-- <div fxLayout="column">
    <app-header></app-header>
    <div fxFlex class="container">
        <mat-sidenav-container fxFlexFill>
            <mat-sidenav-content fxFlexOffset="auto">
                <router-outlet></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
    <app-footer></app-footer>
</div> -->