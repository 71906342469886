import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

import { tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router
  ) { }

  login(user) {
    const params = JSON.stringify(user);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(`${environment.url_api_v2}/api/auth/login`, params, { headers: headers })
      .pipe(
        tap((response: any) => {
          console.log('datainterceptor', response);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('user', response.data.user);
        })
      );
  }

  async logout() {
    if (this.cookieService.get('token')) {

      /* this.cookieService.set('token', '', new Date().getSeconds() + 0, '/', 'localhost', true, 'None');
      this .cookieService.set('user', '', new Date().getSeconds() + 0, '/', 'localhost', true, 'None');*/
      this.cookieService.deleteAll('../');
      // TODO eliminar la cookie de google
      this.http.get(`${environment.url_api}/api/auth/logout`).subscribe(data => {
        console.log('salir', data);
        this.router.navigate(['./home']);
        return true;
      });
    }
    this.router.navigate(['./home']);
    localStorage.clear();
    return true;
  }

  hasUser() {
    return this.cookieService.check('token');
  }

  tokenExpired(token: string): any {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
}
