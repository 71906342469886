<div>
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <button mat-icon-button (click)="sidenav.toggle()" class="menu-icon">
                <mat-icon>menu</mat-icon>
            </button>
            <span style="align-items: center;"><img class="menu-logo" src="assets/logo/logo.png"
                    alt=""></span>
            <span class="menu-spacer"></span>
            <div class="menu-button">
                <a mat-button routerLink="/home" routerLinkActive="active">
                    <mat-icon>home</mat-icon>Inicio
                </a>
                <a mat-button routerLink="/product" routerLinkActive="active">
                    <mat-icon>view_module</mat-icon>Productos
                </a>
                <a mat-button routerLink="/services" routerLinkActive="active">
                    <mat-icon>store</mat-icon>Servicios
                </a>
                <a mat-button routerLink="/aboutus" routerLinkActive="active">
                    <mat-icon>contact_support</mat-icon>Nosotros
                </a>
                <a mat-button routerLink="/contact" routerLinkActive="active">
                    <mat-icon>contacts</mat-icon>Contacto
                </a>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container>
        <mat-sidenav #sidenav>
            <mat-nav-list>
                <a mat-list-item routerLink="/home" routerLinkActive="active">
                    <mat-icon>home</mat-icon> Inicio
                </a>
                <a mat-list-item routerLink="/product" routerLinkActive="active">
                    <mat-icon>visibility</mat-icon> Productos
                </a>
                <a mat-list-item routerLink="/services" routerLinkActive="active">
                    <mat-icon>store</mat-icon> Servicios
                </a>
                <a mat-list-item routerLink="/aboutus" routerLinkActive="active">
                    <mat-icon>contact_support</mat-icon> Nosotros
                </a>
                <a mat-list-item routerLink="/contact" routerLinkActive="active">
                    <mat-icon>contacts</mat-icon> Contacto
                </a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content>
            <div>
                <!--class="container" -->
                <router-outlet (activate)="onActivate($event)"></router-outlet>
            </div>
            <app-footer></app-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>