<footer>
    <div class="container">
        <div class="row between-xs">
            <div class="col-xs-12 col-md-4">
                <div class="box">
                    <h3>SÍGUENOS</h3>
                    <div class="row">
                        <div class="col-xs-2">
                            <div class="box">
                                <a href="https://www.facebook.com/opticaseconogafas" target="_blank"><img
                                        src="assets/footer/facebook.png" alt="opticaeconogafas"></a>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="box">
                                <a style="text-decoration:none" href="https://www.facebook.com/opticaseconogafas" target="_blank">
                                    <p>/opticaseconogafas</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2">
                            <div class="box">
                                <a href="https://www.instagram.com/opticaeconogafas/" target="_blank"><img
                                        src="assets/footer/instagram-png-logo.png" alt="opticaeconogafas"></a>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="box">
                                <a style="text-decoration:none" href="https://www.instagram.com/opticaeconogafas/" target="_blank">
                                    <p>@opticaeconogafas</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-4">
                <div class="box">
                    <h3>FORMAS DE PAGO</h3>
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="box">
                                <img class="logopago" src="assets/footer/dinersclub.png" alt="">
                            </div>
                        </div>
                        <div class="col-xs-3">
                            <div class="box">
                                <img class="logopago" src="assets/footer/pago-MasterCard.png" alt="">
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-xs-3">
                            <div class="box">
                                <img class="logopago" src="assets/footer/visalogo.png" alt="">
                            </div>
                        </div>
                        <div class="col-xs-3">
                            <div class="box">
                                <img class="logopago" src="assets/footer/expresspago.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-4">
                <div class="box">
                    <h3>CONTACTO</h3>
                    <div class="row">
                        <div class="col-xs-2">
                            <div class="box">
                                <img src="assets/footer/house.png" alt="">
                            </div>
                        </div>
                        <div class="col-xs">
                            <div class="box">
                                <p>LOJA, Vicente Rocafuerte y Juan José Peña, 162-42</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2">
                            <div class="box">
                                <img src="assets/footer/smartphone.png" alt="">
                            </div>
                        </div>
                        <div class="col-xs">
                            <div class="box">
                                <p>+593 98 090 4060</p>
                                <p>+593 7 6063949</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-2">
                            <div class="box">
                                <img src="assets/footer/contact.png" alt="">
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="box">
                                <p class="correo">opticaeconogafas@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="copyright center-xs">
    <p>© 2020 Todos los Derechos Reservados | <b>Óptica Econogafas</b></p>
</div>