import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tokenExpired = localStorage.getItem('token') ? this.authService.tokenExpired(localStorage.getItem('token')) : false;

        if (tokenExpired) {
            console.log('token expirado:', tokenExpired);
            this.authService.logout();
            return null;
        }

        request = this.addToken(request);
        return next.handle(request);
    }

    private addToken(request: HttpRequest<any>) {
        let token;
        if (localStorage.getItem('token')) {
            token = localStorage.getItem('token');
        }

        if (token) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    apiKey: environment.apiKey,
                },
            });
            return request;
        } else {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    apiKey: environment.apiKey,
                },
            });
            return request;
        }
        return request;
    }
}
